import PropTypes from 'prop-types'
import React from 'react'

const DoctorList = ({
  doctors,
  onSelectMaster,
  selectedSpeciality,
  inputSearch,
}) => {
  return doctors
    .filter((doctor) => doctor.Name.toLowerCase().includes(inputSearch))
    .sort((a, b) => {
      if (a.Name > b.Name) {
        return 1
      }
      if (a.Name < b.Name) {
        return -1
      }
      return 0
    })
    .map((doctor) => {
      let specialities = doctors.find(
        (doc) => doc.Id === doctor.Id
      ).specialities
      if (
        selectedSpeciality === 'all' ||
        selectedSpeciality === doctor.SpecialityName
      ) {
        return (
          <div
            key={doctor.Id}
            className="choose-master-root__master"
            onClick={() =>
              onSelectMaster(
                doctor.Name,
                doctor.Id,
                doctor.DefaultTime,
                specialities
              )
            }
          >
            <div className="d-flex">
              <div className="choose-master-root__avatar">
                {/* {doctor.Photo !== null ? (
                  <img
                    src={`data:image/png;base64,${doctor.Photo}`}
                    alt=""
                    style={{
                      width: '78px',
                      height: '78px',
                      borderRadius: '15px',
                      border: 'none'
                    }}
                  />
                ) : (
                  doctor.Name.charAt().toUpperCase()
                )} */}

                {doctor.Name.charAt().toUpperCase()}
              </div>
              <div className="d-flex flex-column">
                <div className="choose-master-root__master-name">
                  {doctor.Name.toLowerCase()}
                </div>
                <p className="choose-master-root__speciality">
                  {doctor.SpecialityName}
                </p>
                {/* <div className="choose-master-root__time">
                  <ClockIcon
                    width="12"
                    height="12"
                    mainColor={Colors.NEUTRAL.white}
                    secColor={Colors.NEUTRAL.mint}
                  />
                  <p>Ближ. прием 16:50</p>
                </div> */}
              </div>
            </div>
          </div>
        )
      }
    })
}

DoctorList.propTypes = {
  doctors: PropTypes.array,
}

export default DoctorList
