import React from 'react'
import { Route, Switch } from 'react-router-dom'
import OnlineRecord from './containers/Pages/OnlineRecord/OnlineRecord'
import { ROUTE_RECORD } from './global/Constants/Constants'

const Routes = () => {
  return (
    <Switch>
      <Route path={ROUTE_RECORD} exact component={OnlineRecord} />
    </Switch>
  )
}

export default Routes
